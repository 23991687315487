// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-dr-vault-s-1-tsx": () => import("./../../../src/pages/platform/dr-vault-s1.tsx" /* webpackChunkName: "component---src-pages-platform-dr-vault-s-1-tsx" */),
  "component---src-pages-platform-dr-vault-s-2-tsx": () => import("./../../../src/pages/platform/dr-vault-s2.tsx" /* webpackChunkName: "component---src-pages-platform-dr-vault-s-2-tsx" */),
  "component---src-pages-platform-dr-vault-s-3-tsx": () => import("./../../../src/pages/platform/dr-vault-s3.tsx" /* webpackChunkName: "component---src-pages-platform-dr-vault-s-3-tsx" */),
  "component---src-pages-platform-drc-vault-tsx": () => import("./../../../src/pages/platform/drc-vault.tsx" /* webpackChunkName: "component---src-pages-platform-drc-vault-tsx" */),
  "component---src-pages-platform-index-tsx": () => import("./../../../src/pages/platform/index.tsx" /* webpackChunkName: "component---src-pages-platform-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-white-paper-1-tsx": () => import("./../../../src/pages/white-paper-1.tsx" /* webpackChunkName: "component---src-pages-white-paper-1-tsx" */),
  "component---src-pages-zh-faqs-tsx": () => import("./../../../src/pages/zh/faqs.tsx" /* webpackChunkName: "component---src-pages-zh-faqs-tsx" */),
  "component---src-pages-zh-index-tsx": () => import("./../../../src/pages/zh/index.tsx" /* webpackChunkName: "component---src-pages-zh-index-tsx" */),
  "component---src-pages-zh-resources-tsx": () => import("./../../../src/pages/zh/resources.tsx" /* webpackChunkName: "component---src-pages-zh-resources-tsx" */),
  "component---src-pages-zh-white-paper-1-tsx": () => import("./../../../src/pages/zh/white-paper-1.tsx" /* webpackChunkName: "component---src-pages-zh-white-paper-1-tsx" */)
}

